import {Outlet} from '@remix-run/react';
import {useCallback, useState} from 'react';
import {Footer} from '#/app/components/Footer';
import {Header} from '#/app/components/Header';

export default function RootLayout() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMenuToggle = useCallback(() => {
    setMobileMenuOpen(!isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  return (
    <>
      <Header isMobileMenuOpen={isMobileMenuOpen} toggleMobileMenu={handleMenuToggle} />
      {!isMobileMenuOpen && (
        <div>
          <Outlet />
          <Footer />
        </div>
      )}
    </>
  );
}
