import {Transition} from '@headlessui/react';
import {NavLink} from '@remix-run/react';
import {Fragment, useState} from 'react';
import {FEATURES} from '#/app/content';

export const getClassName = (color: string) => {
  switch (color) {
    case 'amber':
      return 'text-amber-400 hover:text-amber-500';
    case 'emerald':
      return 'text-emerald-400 hover:text-emerald-500';
    case 'cyan':
      return 'text-cyan-400 hover:text-cyan-500';
    case 'pink':
      return 'text-pink-400 hover:text-pink-500';
    case 'rose':
      return 'text-rose-400 hover:text-rose-500';
    case 'teal':
      return 'text-teal-400 hover:text-teal-500';
    default:
      return '';
  }
};

const MOUSE_ENTER_TIMEOUT = 0;
const MOUSE_LEAVE_TIMEOUT = 300;

export const FeaturesMenu = () => {
  let timeout: NodeJS.Timeout;

  const [isOpen, setIsOpen] = useState(false);

  const onHover = (action: string) => {
    if ((!isOpen && action === 'onMouseEnter') || (isOpen && action === 'onMouseLeave')) {
      clearTimeout(timeout);
      timeout = setTimeout(() => setIsOpen(!isOpen), action === 'onMouseEnter' ? MOUSE_ENTER_TIMEOUT : MOUSE_LEAVE_TIMEOUT);
    }
    // else: don't click! 😁
  };

  return (
    <div className="relative">
      <div className="" onMouseEnter={() => onHover('onMouseEnter')} onMouseLeave={() => onHover('onMouseLeave')}>
        <NavLink
          className={({isActive}) =>
            `_nav inline ${isOpen ? 'text-gray-700' : ''} hover:text-gray-400 relative pb-4 cursor-pointer ${isActive ? 'text-sky-500 hover:text-sky-600' : ''
            }`
          }
          onClick={(e) => e.preventDefault()}
          to="/features"
        >
          Features
        </NavLink>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          show={isOpen}
        >
          <div className="absolute left-1/2 z-50 mt-3 w-screen max-w-lg  -translate-x-1/2 px-4 sm:px-0">
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="relative space-y-4 bg-white p-4">
                {FEATURES.map((v) => (
                  <NavLink
                    className={({isActive}) =>
                      `_nav flex items-center px-6 py-3 transition duration-150 ease-in-out rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${isActive ? 'bg-gray-50 hover:bg-gray-100' : 'hover:bg-gray-50'
                      }`
                    }
                    key={v.name}
                    onClick={(e) => setIsOpen(false)}
                    to={v.link}
                  >
                    <div className="flex size-10 shrink items-center justify-center text-white">
                      <v.icon color={v.color} />
                    </div>
                    <div className="ml-6">
                      <p className={`text-lg font-medium ${getClassName(v.color)}`}>{v.name}</p>
                      <p className="text-lg text-gray-500">{v.tagline}</p>
                    </div>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};
