export const MenuIcon = ({color}: {color?: string}) => (
  <svg
    aria-hidden="true"
    className="svg-inline--fa fa-bars fa-w-14"
    data-icon="bars"
    data-prefix="far"
    focusable="false"
    height="100%"
    role="img"
    viewBox="0 0 448 512"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g className={`text-${color}-500 fa-group`}>
      <path
        d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"
        fill="currentColor"
      />
    </g>
  </svg>
);
