import {Link, NavLink, useNavigate} from '@remix-run/react';
import type React from 'react';
import {Container} from '#/app/components/Container';
import {FeaturesMenu, getClassName} from '#/app/components/FeaturesMenu';
import {CloseIcon} from '#/app/components/icons/CloseIcon';
import {MenuIcon} from '#/app/components/icons/MenuIcon';
import {MoreMenu} from '#/app/components/MoreMenu';
import {FEATURES} from '#/app/content';

const Logo = () => (
  <div className="fit-content">
    <Link
      className="_nav group inline-block whitespace-nowrap pr-2 text-4xl font-semibold text-gray-800 hover:cursor-pointer hover:text-gray-600"
      to="/"
    >
      <span className="text-gray-800 group-hover:text-gray-600">⬢</span> ufleet
    </Link>
  </div>
);

type HeaderProps = {
  isMobileMenuOpen: boolean;
  toggleMobileMenu: any;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const navigate = useNavigate();

  const {isMobileMenuOpen, toggleMobileMenu} = props;

  const handleMobileClick = () => {
    toggleMobileMenu();
  };

  return (
    <>
      {isMobileMenuOpen ? (
        <header className="sticky left-0 top-0 z-50 h-screen w-screen ">
          <Container className="flex items-center justify-between py-4">
            <Logo />
            <div className="flex items-center space-x-2 md:hidden">
              <Link
                className="_cta block flex-none rounded-xl border border-transparent bg-gray-800 px-4 py-2.5 text-base font-medium leading-6 text-white transition-colors duration-200 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 focus:ring-offset-white"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/register');
                }}
                to="/register"
              >
                Try Ufleet for Free
              </Link>
              <div
                className="flex size-10 cursor-pointer items-center justify-center text-gray-800 hover:text-gray-600 sm:hidden"
                onClick={toggleMobileMenu}
              >
                <CloseIcon />
              </div>
            </div>
          </Container>
          <Container className="space-y-8 py-8">
            <div>
              <div className="mb-4 text-sm text-gray-600">FEATURES</div>
              <div className="bg-white">
                {FEATURES.map((v) => (
                  <Link
                    className="_nav flex items-center rounded-lg px-1 py-3 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    key={v.name}
                    onClick={handleMobileClick}
                    to={v.link}
                  >
                    <div className="flex size-10 shrink items-center justify-center text-white">
                      <v.icon color={v.color} />
                    </div>
                    <div className="ml-6">
                      <p className={`text-lg font-medium ${getClassName(v.color)}`}>{v.name}</p>
                      <p className="text-lg text-gray-400">{v.tagline}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div>
              <div className="mb-4 text-sm text-gray-600">PRODUCT</div>
              <NavLink
                className="_nav block py-3 text-lg font-medium text-gray-600 hover:text-gray-400"
                onClick={handleMobileClick}
                to="/customers"
              >
                Customers
              </NavLink>
              <NavLink
                className={({isActive}) =>
                  `_nav ${isActive ? 'text-blue-500' : 'block py-3 text-lg font-medium text-gray-600 hover:text-gray-400'}`
                }
                onClick={handleMobileClick}
                to="/pricing"
              >
                Pricing
              </NavLink>
              <NavLink
                className="_nav block py-3 text-lg font-medium text-gray-600 hover:text-gray-400"
                onClick={handleMobileClick}
                to="/blog"
              >
                Blog
              </NavLink>
              <NavLink
                className="_nav block py-3 text-lg font-medium text-gray-600 hover:text-gray-400"
                onClick={handleMobileClick}
                to="/calculator"
              >
                Calculator
              </NavLink>
            </div>
          </Container>
          <div className="bg-gray-100 py-8">
            <Container>
              <a className="_cta block py-3 text-lg font-medium text-gray-600 hover:text-gray-400" href="tel: (855) 252-6050">
                (332) 900-6322
              </a>
              <Link
                className="_nav block py-3 text-lg font-medium text-gray-600 hover:text-gray-400"
                onClick={handleMobileClick}
                target="_blank"
                to="/login"
              >
                Sign In
              </Link>
              <Link
                className="_cta block py-3 text-lg font-medium text-gray-600 hover:text-gray-400"
                onClick={handleMobileClick}
                to="/pricing"
              >
                Get Started
              </Link>
            </Container>
          </div>
        </header>
      ) : (
        <>
          <div className="bg-white" style={{height: 78}} />
          <header className="fixed top-0 z-40 w-full bg-white">
            <Container className="flex items-center justify-between py-4">
              <Logo />
              <div className="flex items-center space-x-2 md:hidden">
                <Link
                  className="_cta block flex-none rounded-xl border-transparent bg-gray-800 px-4 py-2.5 text-base font-medium leading-6 text-white transition-colors duration-200 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 focus:ring-offset-white"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/register');
                  }}
                  to="/register"
                >
                  Try Ufleet for Free
                </Link>
                <div
                  className="flex size-8 cursor-pointer items-center justify-center text-gray-800 hover:text-gray-700 md:hidden"
                  onClick={toggleMobileMenu}
                >
                  <MenuIcon />
                </div>
              </div>
              <div className="hidden items-center justify-center space-x-8 text-lg font-medium text-gray-800 md:flex">
                <FeaturesMenu />
                <NavLink
                  className={({isActive}) =>
                    `_nav hidden lg:block ${isActive ? 'text-sky-500 hover:text-sky-600' : ' hover:text-gray-400'}`
                  }
                  to="/customers"
                >
                  Customers
                </NavLink>
                <NavLink
                  className={({isActive}) =>
                    `_nav hidden lg:block ${isActive ? 'text-sky-500 hover:text-sky-600' : ' hover:text-gray-400'}`
                  }
                  to="/pricing"
                >
                  Pricing
                </NavLink>
                <NavLink
                  className={({isActive}) =>
                    `_nav hidden xl:block ${isActive ? 'text-sky-500 hover:text-sky-600' : ' hover:text-gray-400'}`
                  }
                  to="/blog"
                >
                  Blog
                </NavLink>
                <NavLink
                  className={({isActive}) =>
                    `_nav hidden xl:block ${isActive ? 'text-sky-500 hover:text-sky-600' : ' hover:text-gray-400'}`
                  }
                  to="/calculator"
                >
                  Calculator
                </NavLink>
                <MoreMenu />
              </div>
              <div className="hidden items-center justify-end space-x-6 text-lg font-medium text-gray-800 md:flex">
                <a className="_cta block whitespace-nowrap  hover:text-gray-400" href="tel: (855) 252-6050">
                  (332) 900-6322
                </a>
                <Link className="_nav block whitespace-nowrap hover:text-gray-400" target="_blank" to="/login">
                  Sign In
                </Link>
                <Link
                  className="_cta block w-full flex-none rounded-xl border border-transparent bg-gray-800 px-4 py-2.5 text-base leading-6 text-white transition-colors duration-200 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 focus:ring-offset-white sm:w-auto "
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/register');
                  }}
                  to="/register"
                >
                  Try Ufleet for Free
                </Link>
              </div>
            </Container>
          </header>
        </>
      )}
    </>
  );
};
