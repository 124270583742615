export const CloseIcon = () => (
  <svg
    aria-hidden="true"
    className="svg-inline--fa fa-times fa-w-10"
    data-icon="times"
    data-prefix="far"
    focusable="false"
    height="100%"
    role="img"
    viewBox="0 0 448 512"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
      fill="currentColor"
    />
  </svg>
);
